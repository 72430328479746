<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader
            :title="headercontent.title"
            :addlink="headercontent.addlink"
          />
        </v-col>
        <v-col md="12">
          <v-data-table
              :items="allNotifications.results"
              :headers="headers"
              :server-items-length="allNotifications.pagination.total"
              :options.sync="options"
              :loading="loading"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon
                v-if="!sendLoading || selectedId !== item._id"
                class="cursorPointer"
                @click="fireSendNotification(item._id)"
              >
                mdi-send
              </v-icon>
              <v-progress-circular
                indeterminate
                v-if="sendLoading && selectedId === item._id"
              ></v-progress-circular>
              <v-icon
                v-if="!sendLoading || selectedId !== item._id"
                @click="deleteNotificationAct(item._id)"
              >
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:default v-if="allNotifications.results">
              <thead>
                <tr>
                  <th class="text-left">S/N</th>
                  <th class="text-left">Name</th>
                  <th class="text-left">Body</th>
                  <th class="text-left">Action</th>
                  <th class="text-left">Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in allNotifications.results"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td class="text-capitalize">{{ item.title }}</td>
                  <td class="text-capitalize">{{ item.body }}</td>
                  <td class="text-capitaliz text-left">
                    <v-icon
                      v-if="!sendLoading || selectedId !== item._id"
                      class="cursorPointer"
                      @click="fireSendNotification(item._id)"
                    >
                      mdi-send
                    </v-icon>
                    <v-progress-circular
                      indeterminate
                      v-if="sendLoading && selectedId === item._id"
                    ></v-progress-circular>
                  </td>
                  <td>
                    <v-icon
                      v-if="!sendLoading || selectedId !== item._id"
                      @click="deleteNotificationAct(item._id)"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
          <div v-if="loading">
            <v-skeleton-loader type="image" height="200"></v-skeleton-loader>
            <v-skeleton-loader type="image" height="200"></v-skeleton-loader>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Notification",
  components: { PageHeader },
  data: () => ({
    headercontent: {
      title: "Notifications",
      addlink: "/dashboard/notification/add",
    },
    loading: true,
    sendLoading: false,
    selectedId: undefined,
    headers: [
      { text: 'Name', value: 'title', sortable: false },
      { text: 'Body', value: 'body', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    options: {
      itemsPerPage: 5,
      page: 1
    }
  }),
  methods: {
    ...mapActions([
      "getNotifications",
      "deleteNotification",
      "sendNotification"
    ]),
    async fireSendNotification(id) {
      this.sendLoading = true;
      this.selectedId = id;
      await this.sendNotification(id);
      this.sendLoading = false;
      this.selectedId = undefined;
      alert("Notification Sent");
    },
    async deleteNotificationAct(id) {
      this.sendLoading = true;
      this.selectedId = id;
      await this.deleteNotification(id);
      this.sendLoading = false;
      this.selectedId = undefined;
    },
    async reloadNotifications() {
      this.loading = true;
      await this.getNotifications({
        limit: this.options.itemsPerPage,
        skip: (this.options.page - 1) * this.options.itemsPerPage,
      });
      this.loading = false;
    },
  },
  async created() {
    await this.reloadNotifications();
  },
  computed: {
    ...mapGetters(["allNotifications"]),
  },
  watch: {
    options: {
      async handler() {
        await this.reloadNotifications();
      },
      deep: true,
    }
  }
};
</script>